.carousel {
  //padding-top: 20px;
  width: 100%;
  overflow: hidden;
  height: auto;
  position: relative;
  margin-bottom: 1em
}

.carousel a {
  display: block;
  height: 100%;
  width: 100%;
  color: black
}


.carousel ul {
  position: relative;
  list-style: none;
  list-style-type: none;
  margin: 0;
  height: 260px;
  padding: 0;
}
.carousel > ul > li {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 550px;
  float: left;
  margin-right: 15px;
  background: #f2f2f2;
  text-align: left;
  border: 1px solid grey;

}
.carousel ul li img {
  max-width: 140px;
  max-height: 100%;
  display: block;
  margin-left: 15px;
  padding-top: 8%;
  height: auto;
}
.car-content {
  margin: 0 auto;
  text-align: left;
  width: 76%;
  left: 24%;
  position: absolute;
  top: 0;
  padding: 15px;
  height: 100%;
  font-size: 16px;
}
.car-content h4 {
  margin-top: 0;
  color: $brand-primary;
  margin-bottom:20px
}
.car-content ul {
  list-style:initial
}
.car-content p {
  line-height: 1.5em
}
.text-center {
  width: 100%;
  margin: 0 auto;
  text-align: center
}
.car-content p {
  line-height: 1.3em!important;
  color: black
}
.car-content p:nth-child(even) { 
    margin-top: 10px!important; 
}
.carousel>ul>li:not(.active) {
  opacity: 0.3;
}
