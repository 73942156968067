.footer-image .textwidget a{
  color: white;
}
.footer-image .textwidget a:hover{
  color: white;
  text-decoration: underline;
}
.footer-image .textwidget a:active{
  color: white;
}

label{
  font-weight: 400;
}

ul {
  line-height: 1.2em;
}
.main {
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;

}
.arrow-down {
  background: url(../../assets/images/icon-scroll-down.png) no-repeat center center;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  opacity: 0.7;
  z-index: 99999;
}
.arrow-down:hover {
  background: url(../../assets/images/icon-scroll-down.png) no-repeat center center;
  opacity: 1;
}

.img-responsive.wp-post-image {
  min-width: 100%;
  margin-top: 0;
}

.container-fluid.parallax-image-container {
  padding: 0;
  margin: 0;
}
i.fa {
  z-index: 0;
}
#text-2 {
  background: $brand-secondary;
  padding: 2em 0;
}
.image-container {
  position: relative;
  display: block;
}
.subhead-image {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  background: $brand-primary;
  padding: .3em .8em;
  font-size: 2em
}
.page-id-102 .subhead-image {
  text-align: left;
  width: auto!important;
  position: absolute;
  left: 0;
  margin-left: 20px;
  max-width: 37%;
}
.page-id-104 h2.subhead-image, .page-id-19 h2.subhead-image, .page-id-13 h2.subhead-image, .page-id-7 h2.subhead-image {
  left: 20px;
  right: inherit;
}
.parallax-image {
  height: 450px;
  text-align: center;
  font-size: 130%;
}
.parallax-window {
  height: 450px;
}

.parallax-window h3 {
  padding-top: 2em;
  color: white;
}
.parallax-window h4 {
  color: white;
}
.parallax-image .textwidget {
  padding-top: 0;
  background-image: url('');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15%;
}
.widget .col-md-12 {
  padding: 0;
}
.container.slick-slider.slick-initialized {
  padding-top: 2em;
}
.team-title {
  text-transform: uppercase;
}
.alm-listing {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.moretag {
  display: block;
  padding-top: 1em;
  text-transform: uppercase;
}
.moretag:before {
  content: " ";
  background: url(../../assets/images/icon-pfeil-weiter.png) no-repeat center center;
  padding: 1.1em;
  margin-right: 15px;
}
.sep-30 {
  padding: 15px 0;
}
.sep-60 {
  padding: 30px 0;
}
.sep-120 {
  position: relative;
  display: inline-block;
  width: 100%;
}
.textwidget p {
  font-size: 16px;
  line-height: 1.5em!important;
}
ul li p {
  margin-bottom: 0;
}
.projects .sep-black-line {
  width: 80%;
  float: right;
}
.tooltip {
  display: none;
  position: absolute;
  border: 1px solid #333;
  background-color: #161616;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  opacity: 1;
  max-width: 250px;
}
.social-icons {
  text-align: center;
  margin: 0 auto;
}
a.team-social {
  padding: 1.5em;
  display: inline-block;
  margin-right: 15px;
  margin-top: 15px;
}
a.team-social.facebook {
  background: url(../../assets/images/icon-facebook-40px.png) no-repeat center center;
}
a.team-social.homepage {
  background: url(../../assets/images/icon-website-40px.png) no-repeat center center;
}
a.team-social.email {
  background: url(../../assets/images/icon-mail-40px.png) no-repeat center center;
}
a.team-social.print {
  background: url(../../assets/images/icon-drucken-40px.png) no-repeat center center;
}
.small-image{
  max-width: 150px;
}
.text-15 ul {
  padding: 0;
  list-style: none;
}
.text-15 li {
  padding: 0;
  list-style: none;
  margin: 15px 0;
  padding-left: 30px;
}
i.fa.fa-file-pdf-o {
  margin-left: -25px;
}
#ufo16 {
  position: absolute;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  padding-top: 8px;
}
#ufo16 img {
  width: 16%;
  max-width: 192px;
  margin-left: 60%;
  animation: ufo 20s ease-in-out infinite;
}
@keyframes ufo {
  0%   { margin-left: 180%; width: 80%; max-width: 960px; }
  10%  { margin-left: 60%; width: 16%; max-width: 192px; }
  70%  { margin-left: 60%; width: 16%; max-width: 192px;  }
  80% { margin-left: 110%; width: 4%; max-width: 48px; }
  100% { margin-left: 110%; width: 4%; max-width: 48px; }
}
#ghosty {
  position: absolute;
  z-index: 5;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}
#ghosty img {
  width: 7%;
  max-width: 52px;
  margin-left: -300%;
  animation: ghosty6 15s linear  infinite;
}

@keyframes ghosty6 {
  0%   { margin-left: -300%; }
  100% { margin-left: 100%; }
}

@media screen and (min-width: 992px) {
  #ghosty img {
    max-width: 70px;
  }
}
@media screen and (min-width: 1200px) {
  #ghosty img {
    max-width: 84px;
  }
}
@media (max-width: 480px) {
  h2.subhead-image {
    display: none;
  }
}
