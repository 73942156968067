footer p {
  font-size: 14px;
}
footer h3.brand img {
    max-width: 20%;
}
footer.content-info {
  background: #2F2F2F;
  color: white;
  padding-top: 3em;
  padding-bottom: 3em;
}
// .footer-image {
//   background: #2F2F2F url(/wp-content/uploads/2016/03/rohwer-footer.gif) no-repeat 97% 0%;
//       background-size: 15%;
// }
span.pipe-sep {
  display: inline-block;
  font-size: 2em;
  padding: 0 5px;
}
.blog-post-footer {
  display: flex;
}
h4.blog-post.footer-cat {
  display: inline-block;
}
#menu-footer {
  list-style: none;
  padding-left: 0;
}
#menu-footer li a {
  color: white;
  font-family: $h1;
  font-size: 16px;
}
footer .textwidget a img {
  padding-right: 0.5em;
}
footer .textwidget {
  font-family: $body-font;
}
footer h3.brand {
  font-size: 2.0em;
  padding-bottom: 1em;
  font-family: $h1;
}
.blog-post-footer {
  color: $link-color;
}
.footer-logo img {
  height: 50px;
  FLOAT: inherit;
  margin-top: -1.5em;
}
ul#menu-footer {
  margin-top: -4px;
}