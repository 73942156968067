.banner {
  height: 100px;
  position: relative;
  border-top: 5px solid white;
  border-bottom: 5px solid $brand-secondary;
  background-color: $brand-primary;
  width: 100%;
  z-index: 5!important
}
.logo-container {
  background: transparent url(/wp-content/uploads/2016/01/RohwerGut-Logo_weiss.svg) no-repeat center center;
  background-size: contain;
  height: 92px;
  width: 100%;
  min-width: 160px;
  background-position-y: 48%;
  position: absolute;
  top: 0;
  left: 15px;
}
a.homelink {
  height: 93px;
  position: relative;
}
.page-header {
  padding-bottom: 0 !important;
}
@media (max-width: 480px) {
  .banner {
    height: 120px;
    position: relative;
    border-top: 5px solid $brand-primary;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b3bead+0,dfe5d7+60,fcfff4+100 */
    //background: white;
  }
}
