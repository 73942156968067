.icon-large {
  font-size: 3em;
  color: #666;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.icon-large:hover,
.wpb-service-style-4:hover .icon-large {
  text-shadow: 5px 5px 2px rgba(255,255,255,0);
}
.icon-mini-effect {
  position: relative;
  display: block;
  z-index: 1;
  font-size: 1.2em;
  color: #fff;
  top: 4px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.icon-medium-effect {
  position: relative;
  display: block;
  z-index: 1;
  font-size: 2em;
  color: #fff;
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.icon-large-effect {
  position: relative;
  display: block;
  z-index: 1;
  font-size: 2.6em;
  color: $brand-primary;
  width: 80px;
  height: 80px;
  line-height: 78px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.icon-mini-effect:after,
.icon-medium-effect:after,
.icon-large-effect:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/*First Icon Effect */
.icon-effect-1 {
  background-color: #fff;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  transition: color 0.4s;
}
.icon-effect-1:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}
.gray-icon.icon-effect-1:after {
  background: #444;
}
.icon-effect-1.gray-icon:hover,
.wpb-service-style-4:hover .gray-icon.icon-effect-1 {
  color: #444;
}
.icon-effect-1:hover:after,
.wpb-service-style-4:hover .icon-effect-1:after {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
}
.service-icon { 
  text-align: center;
  display: inline-block;
  background-size: contain!important;
  height: 200px;
  width: 100%;
}
.service-icon.pixelman {
  background: transparent url(/wp-content/uploads/2016/04/teaser-seminare.gif) no-repeat center center;
}
.service-icon.netzwerk {
  background: transparent url(/wp-content/uploads/2016/04/teaser-netzwerkpartner.gif) no-repeat center center;
}
.service-icon.download {
  background: transparent url(/wp-content/uploads/2016/09/teaser-downloads.gif) no-repeat center center;
}
.service {
  text-align: center;
}
.service-icon {
  /* background: blue; */
}
.fa {
  background: lightgrey;
}
