input#burger-check {
  display: none;
}
@media only screen and (max-width: 1024px) {
  // Burger menu button
  $burger--thickness: 3px;
  .burger {
    $width: 35px;
    margin-right: 10px;
    display: inline-block;
    border: 0;
    background: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
    float: right;
    border-bottom: $burger--thickness solid white;
    width: $width;

    transition: border-bottom 1s ease-in-out;
    -webkit-transition: border-bottom 1s ease-in-out;

    // Fix for extra space in Firefox
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:before {
      content: "";
      display: block;
      border-bottom: $burger--thickness solid white;
      width: 100%;
      margin-bottom: 6px;
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
    }

    &:after {
      content: "";
      display: block;
      border-bottom: $burger--thickness solid white;
      width: 100%;
      margin-bottom: 6px;
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
    }
  }
  label.burger {
    position: relative;
    top: 45px;
    right: 15px;
    width: 40px;
    z-index: 9999999999;
  }
  .burger-check {
    display: none;
  }
  .burger-check:checked ~ .burger {
    border-bottom: $burger--thickness solid transparent;
    transition: border-bottom 0.8s ease-in-out;
    -webkit-transition: border-bottom 0.8s ease-in-out;
    //border-color: $brand-primary;
    &:before {
      transform: rotate(-405deg) translateY(1px) translateX(-3px);
      -webkit-transform: rotate(-405deg) translateY(1px) translateX(-3px);
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
      border-color: $brand-primary;
    }
    &:after {
      transform: rotate(405deg) translateY(-4px) translateX(-5px);
      -webkit-transform: rotate(405deg) translateY(-4px) translateX(-5px);
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
      border-color: $brand-primary;
    }
  }

  // * * * Navigation * * *
  $navigation__transition: max-height 0.5s ease-in-out;

  #menu-main {
    overflow: hidden;
    max-height: 0;
    transition: $navigation__transition;
  }

  .burger-check:checked ~ #menu-main {
    max-height: 100vh!important;
    //min-height: 100vh!important;
    transition: $navigation__transition;
    overflow: scroll;
    padding-top: 5vh!important;
    transition: transform 0.5s ease-in-out;
    z-index: 999
  }
  #menu-main > li.active > a {
    color: black!important;
  }
  #menu-main{

    li{
      list-style: none;
      display: inline-block;
      //margin-left: 40px;
      font-size: 18px;
      display: block;
      margin-left: 0;
      margin-bottom: 15px;

      a{
        padding-bottom: 5px;
        color: $brand-primary;
        position: relative;
        font-weight: 200;
        &.active{
          // color: $brand-primary;
          // border-bottom: 2px solid $brand-primary;
          &:before{
            position: absolute;
            width: 7px;
            height: 7px;
            content: '';
            top: -5px;
            right: -7px;
            background: $brand-primary;

          }
        }
        &:hover, &:focus{
          text-decoration: none;
          color: $brand-primary;
        }
      }
      &:first-child{
        margin-left: 0;
      }
    }

  }
  .col-md-12.nav-holder {
    position: initial;
  }
  #menu-main {
    background: white;
    display: block;
    z-index: 9999;
    height: auto;
    min-height: 0;
    top:0
  }
  #menu-main > li {
    z-index: 9999999999;
    display: block;
    position: relative;
    display: block;
    width: 100vw;
    text-align: center;
    margin: 0 auto!important;
    line-height: 1.5em
  }
  #menu-main > li:hover {
    margin: 0 auto!important;
  }
  #menu-main > li:hover a{
    color: black;
    margin: 0 auto!important;
  }
  #menu-main li a{
    font-size: 20px;
    padding: .5em;
    text-align: center;
    font-weight: 300;
    max-width: 150px;
    margin: 0 auto;
  }
  ul#menu-main {
    width: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
  }
  .opend .sub-menu {
    position: relative!important;
  }
  nav.nav-primary {
    display: block;
  }
  a.brand {
    z-index: 9999999;
    display: block;
    position: relative;
    background-color: #fff;
    margin-right: -15px;
    left: 0;
    margin-left: -15px;
    padding-top: 1em;
    padding-left: 1em;
  }
  .nav>li {
    border-bottom: 1px solid $brand-primary;
  }
  .nav>li {
    width: 76%;
    /* margin: 0 auto; */
    /* margin-right: auto; */
    margin-left: auto;
  }
  .nav>li>a {
    text-align: left;
  }
  .nav>li,
  .nav>li>a {
    display: block;
  }
  li.menu-item-has-children > a:before {
    content: ">";
    position: relative;
    margin-right: 5px;
  }
  li.menu-item-has-children.opend > a:before {
    content: ">";
    transform: rotate(90deg);
    display: inline-block;
    margin-right: 7px;

  }
}
