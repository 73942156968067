@media (max-width: 768px) {
  .carousel ul {
    min-height: 295px;
  }
  .carousel ul li img {
    display: none;
  }
  .car-content {
    width: 100%;
    left: 0;
  }
  .subhead-image{
    font-size: 1em;   
  }
}
