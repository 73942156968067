//
// Navs
// --------------------------------------------------
.nav {
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
  text-align: center;
}
#shiftnav-toggle-main {
  background: $brand-primary!important;
}
#menu-main {
  text-align: right;
}
.nav-primary {
  font-family: $body-font;
  background-color: transparent;
}
.admin-bar .nav-primary {
  top: 32px;
}
.nav-primary ul li {
  display: inline-block;
  background: transparent;
  padding: 0;
}
.nav-primary ul li:hover {
  background-color: transparent;
}
.sub-menu {
  background-color: $brand-primary;
  display: block;
  z-index: 9999999999
}
.sub-menu li {
  display: block!important;
}
.sub-menu li:hover {
  background-color: $brand-secondary!important;
}

.sub-menu li a {
    color: white!important;
}
.nav>li>a {
  position: relative;
  display: block;
  padding: 5px 20px;
}
.active.menu-item {
  font-family: $body-font;
}
li.active a {
    color: $brand-secondary!important;
}
.nav-primary ul li a:first-child {
  border-left: none;
}
.nav-primary ul li a { 
  color: #FFF;
  font-size: 1.2em;
  border-left: 1px solid #FFF;
}
.nav-primary .active ul li a {
  color: #FFF;
  font-family: $body-font;
}
.nav-primary .sub-menu li:hover a {
  color: $brand-primary!important;
}

.nav-primary ul li a:hover:not(.gecko) {
  background-color: transparent;

}
.nav-primary .sub-menu li a:hover:not(.gecko) {
  background-color: transparent;
  color: $brand-primary;
}
.gecko .nav-primary ul li a:hover {
  font-weight: normal;
  background-color: transparent;
}
.sub-menu {
  display: none;
}
.nav-primary ul li:hover > .sub-menu {
  display: inline-block;
  position: absolute;
}
.sub-menu li {
  font-size: 0.7em;
  border-bottom: 1px solid #FFF;
}
.sub-menu li:hover {
  background-color: $brand-primary;
}
.main-navigation {
  clear: both;
  margin: 0 auto;
  max-width: 1080px;
  min-height: 45px;
  position: relative;
}
ul.nav-primary,
div.nav-primary > ul {
  margin: 0;
  padding: 0 40px 0 0;
}
.nav-primary li {
  display: inline-block;
  position: relative;
}
.nav-primary li a {
  display: block;
  font-size: 15px;
  line-height: 1;
  padding: 15px 20px;
  text-decoration: none;
  text-align: left;
  width: auto;
}
.nav-primary li:hover > a,
.nav-primary li a:hover,
.nav-primary li:focus > a,
.nav-primary li a:focus {
  color: whitesmoke;
}
.nav-primary .sub-menu,
.nav-primary .children {
  border: none;
  border-top: 0;
  padding: 0;
  //position: absolute;
  left: 0;
  z-index: 99999;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.nav-primary .sub-menu ul,
.nav-primary .children ul {
  border-left: 0;
  left: 100%;
  top: 0;
}
ul.nav-primary ul a,
.nav-primary ul ul a {
  color: #fff;
  margin: 0;
  width: 200px;
}
ul.nav-primary ul a:hover,
.nav-primary ul ul a:hover,
ul.nav-primary ul a:focus,
.nav-primary ul ul a:focus {
  background-color: black;
  color: white;
}
ul.nav-primary li:hover > ul,
.nav-primary ul li:hover > ul,
ul.nav-primary .focus > ul,
.nav-primary .focus > ul {
  clip: inherit;
  overflow: inherit;
  height: inherit;
  width: inherit;
}
.nav-primary .current_page_item > a,
.nav-primary .current_page_ancestor > a,
.nav-primary .current-menu-item > a,
.nav-primary .current-menu-ancestor > a {
  color: #bc360a;
  font-style: italic;
}
.menu-toggle {
  display: none;
}
// Base class
// --------------------------------------------------
.nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  @include clearfix;
  > li {
    position: relative;
    display: block;
    > a {
      position: relative;
      display: block;
      padding: $nav-link-padding;
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: transparent;
      }
    }
    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      color: $nav-disabled-link-color;
      &:hover,
      &:focus {
        color: $nav-disabled-link-hover-color;
        text-decoration: none;
        background-color: transparent;
        cursor: $cursor-disabled;
      }
    }
  }
  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: $nav-link-hover-bg;
      border-color: $link-color;
    }
  }
  // Nav dividers (deprecated with v3.0.1)
  //
  // This should have been removed in v3 with the dropping of `.nav-list`, but
  // we missed it. We don't currently support this anywhere, but in the interest
  // of maintaining backward compatibility in case you use it, it's deprecated.
  .nav-divider {
    @include nav-divider;
  }
  // Prevent IE8 from misplacing imgs
  //
  // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
  > li > a > img {
    max-width: none;
  }
}
// Tabs
// -------------------------
// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 1px solid $nav-tabs-border-color;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;
    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: $line-height-base;
      border: 1px solid transparent;
      border-radius: $border-radius-base $border-radius-base 0 0;
      &:hover {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
      }
    }
    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $nav-tabs-active-link-hover-color;
        background-color: $nav-tabs-active-link-hover-bg;
        border: 1px solid $nav-tabs-active-link-hover-border-color;
        border-bottom-color: transparent;
        cursor: default;
      }
    }
  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    @extend .nav-justified;
    @extend .nav-tabs-justified;
  }
}
// Pills
// -------------------------
.nav-pills {
  > li {
    float: left;
    // Links rendered as pills
    > a {
      border-radius: $nav-pills-border-radius;
    }
    + li {
      margin-left: 2px;
    }
    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $nav-pills-active-link-hover-color;
        background-color: $nav-pills-active-link-hover-bg;
      }
    }
  }
}
// Stacked pills
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}
// Nav variations
// --------------------------------------------------
// Justified nav links
// -------------------------
.nav-justified {
  width: 100%;
  > li {
    float: none;
    > a {
      text-align: center;
      margin-bottom: 5px;
    }
  }
  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }
  @media (min-width: $screen-sm-min) {
    > li {
      display: table-cell;
      width: 1%;
      > a {
        margin-bottom: 0;
      }
    }
  }
}
// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
  border-bottom: 0;
  > li > a {
    // Override margin from .nav-tabs
    margin-right: 0;
    border-radius: $border-radius-base;
  }
  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    border: 1px solid $nav-tabs-justified-link-border-color;
  }
  @media (min-width: $screen-sm-min) {
    > li > a {
      border-bottom: 1px solid $nav-tabs-justified-link-border-color;
      border-radius: $border-radius-base $border-radius-base 0 0;
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-bottom-color: $nav-tabs-justified-active-link-border-color;
    }
  }
}
// Tabbable tabs
// -------------------------
// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
// Dropdowns
// -------------------------
// Specific dropdowns
.nav-tabs .dropdown-menu {
  // make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  @include border-top-radius(0);
}
