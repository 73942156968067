

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
.wpcf7-form-control {
  width: 100%;
}
.wpcf7-textarea {
  width: 100%;
}
.wpcf7-form-control,
textarea.wpcf7-form-control {
  -webkit-transition: background 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out;
  -ms-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  line-height: 1.4;
  color: #000;
  font-size: 14px;
  padding: 8px;
  border: none;
  margin-bottom: 8px;
  background-color: $brand-secondary;
  -webkit-box-shadow: 4px 8px 16px 0 rgba(0,0,0,0);
  box-shadow: 4px 8px 16px 0 rgba(0,0,0,0);
}
.wpcf7-form-control:hover,
textarea.wpcf7-form-control:hover {
  background-color: $brand-secondary;
  -webkit-box-shadow: 4px 8px 16px 0 rgba(0,0,0,0.3);
  box-shadow: 4px 8px 16px 0 rgba(0,0,0,0.3);
}
.wpcf7-form-control:focus,
textarea.wpcf7-form-control:focus {
  background-color: $brand-secondary!important;
  -webkit-box-shadow: 4px 8px 16px 0 rgba(0,0,0,0);
  box-shadow: 4px 8px 16px 0 rgba(0,0,0,0);
}
